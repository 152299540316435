import React from "react";
import { Link } from "gatsby";
import { LogoWrapper } from "./logo.stc";
import logo from "../../../assets/img/logo/logo.png";

const Logo = props => {
    return (
        <LogoWrapper {...props}>
            <Link to="/" className="big-cursor">
                <img src={logo} alt="Benjamin Po" className="logo" />
            </Link>
        </LogoWrapper>
    );
};

export default Logo;
