import styled, { css } from "styled-components";

import { device } from "../../../theme";

export const LogoWrapper = styled.div`
    position: relative;
    .logo {
        max-width: 40%;
    }
    @media ${device.xlarge} {
        .logo {
            max-width: 40%;
        }
    }
    @media ${device.large} {
        .logo {
            max-width: 60%;
        }
    }
    @media ${device.medium} {
        .logo {
            max-width: 80%;
        }
    }
    @media ${device.small} {
        .logo {
            max-width: 180px;
        }
    }
    ${props =>
        props.responsive &&
        css`
            ${props =>
                props.responsive.xlarge &&
                css`
                    @media ${device.xlarge} {
                        max-width: ${props => props.responsive.xlarge.maxwidth};
                    }
                `};
            ${props =>
                props.responsive.large &&
                css`
                    @media ${device.large} {
                        max-width: ${props => props.responsive.large.maxwidth};
                    }
                `};
            ${props =>
                props.responsive.medium &&
                css`
                    @media ${device.medium} {
                        max-width: ${props => props.responsive.medium.maxwidth};
                    }
                `};
            ${props =>
                props.responsive.small &&
                css`
                    @media ${device.small} {
                        max-width: ${props => props.responsive.small.maxwidth};
                    }
                `};
            ${props =>
                props.responsive.xsmall &&
                css`
                    @media ${device.xsmall} {
                        max-width: ${props => props.responsive.xsmall.maxwidth};
                    }
                `};
        `}
`;
