import React from "react";

import NavBar, {
    NavItem,
    NavLink
} from "../../shared/navbar";

import { MobileMenuWrap } from "./mobilemenu.style";

const MobileMenu = ({ menuData }) => {
    const menuarr = menuData;

    return (
        <MobileMenuWrap>
            <NavBar>
                {menuarr.map((menu, i) => {
                    const menuIndex = i;
                    return (
                        <NavItem
                            key={`mainmenu-${menu.node.id}`}
                            className="menu-item"
                            id={`menu-item-${menuIndex}`}
                        >
                            <NavLink path={menu.node.path}>
                                {menu.node.title}
                            </NavLink>
                        </NavItem>
                    );
                })}
            </NavBar>
        </MobileMenuWrap>
    );
};

export default MobileMenu;
