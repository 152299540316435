import styled from "styled-components";

export const SubmenuWrap = styled.ul`
    position: relative;
    min-width: 100%;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;
    transition-property: all;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
    transform-origin: top;
`;
