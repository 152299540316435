import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import Text from "../../../components/shared/text";
import { FooterWrap, FooterBottom, FooterBottomLeft } from "./footer.stc";

const Footer = ({ copyrightStyle }) => {
    const siteQueryData = useStaticQuery(graphql`
        query footerSiteDataQuery {
            site {
                siteMetadata {
                    copyright
                }
            }
        }
    `);

    const { copyright } = siteQueryData.site.siteMetadata;

    return (
        <FooterWrap>
            <FooterBottom>
                {copyright && (
                    <FooterBottomLeft>
                        <Text {...copyrightStyle}>
                            &copy; {new Date().getFullYear()} {copyright}
                        </Text>
                    </FooterBottomLeft>
                )}
            </FooterBottom>
        </FooterWrap>
    );
};

Footer.propTypes = {
    logoStyle: PropTypes.object,
    headingStyle: PropTypes.object,
    emailTextStyle: PropTypes.object,
    emailLinkStyle: PropTypes.object,
    widgetTitleStyle: PropTypes.object,
    addresTextStyle: PropTypes.object,
    addresSpanStyle: PropTypes.object,
    copyrightStyle: PropTypes.object,
    socialStyle: PropTypes.object
};

Footer.defaultProps = {
    logoStyle: {
        responsive: {
            medium: {
                maxwidth: "125px"
            }
        }
    },
    headingStyle: {
        as: "h1",
        color: "#f8f8f8",
        fontSize: "100px",
        fontweight: 700,
        lineHeight: 1,
        responsive: {
            xlarge: {
                fontSize: "65px"
            },
            medium: {
                fontSize: "30px"
            }
        }
    },
    emailTextStyle: {
        m: 0
    },
    emailLinkStyle: {
        color: "#101010",
        fontSize: "30px",
        fontweight: 500,
        opacity: 0.7,
        responsive: {
            xlarge: {
                fontSize: "20px"
            },
            medium: {
                fontSize: "16px"
            }
        }
    },
    widgetTitleStyle: {
        color: "#c1c1c1",
        fontSize: "16px",
        fontweight: 600,
        texttransform: "uppercase",
        letterspacing: "1px",
        responsive: {
            small: {
                fontSize: "16px"
            }
        }
    },
    addresTextStyle: {
        color: "#fff",
        opacity: 0.7,
        responsive: {
            medium: {
                fontSize: "16px",
                lineHeight: "24px"
            }
        }
    },
    addresSpanStyle: {
        as: "span",
        display: "block"
    },
    copyrightStyle: {
        color: "#101010",
        m: 0,
        fontSize: "16px",
        letterspacing: "1px",
        fontweight: 400,
        opacity: 0.7,
        responsive: {
            medium: {
                fontSize: "10px",
                letterspacing: "0.5px"
            }
        }
    },
    socialStyle: {
        opacity: 0.7,
        space: 30,
        responsive: {
            medium: {
                space: 20,
                fontSize: "16px"
            }
        }
    }
};

export default Footer;
